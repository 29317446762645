import { useState, useEffect, useRef } from 'react'

export const useDelayLoading = () => {
    const [showContent, setShowContet] = useState<boolean>(false)

    // I was unable to scroll the content div with keyboard
    //  as it wasn't focused on load. Thus added this useEffect hook to
    // focus on the body when initially loaded

    const contentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (contentRef.current) contentRef.current.focus()
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowContet(true)
        }, 850)

        return () => clearTimeout(timeout)
    }, [])

    return { showContent, contentRef }
}
