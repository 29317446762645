import React from 'react'
import { RootElement } from './src/components/root-element'
import { Layout } from './src/components/layout'

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>
}

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}
