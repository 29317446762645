import React from 'react'
import { ParentProvider } from '@app/context/ParentContext'
import { LocationProvider } from '@reach/router'

export const RootElement = ({ children }: { children: JSX.Element | JSX.Element }) => {
    return (
        <LocationProvider>
            <ParentProvider>{children}</ParentProvider>
        </LocationProvider>
    )
}
