import React from 'react'

import { HeaderContainer, HeaderLink } from './styles'
import { MobileHeader } from '@app/components/mobile/header'
import { useParent } from '@app/hooks'

export const Header = () => {
    const { isLargeScreen } = useParent()

    if (isLargeScreen) {
        return (
            <HeaderContainer>
                <HeaderLink to='/'>kapilbastola.com.np</HeaderLink>
            </HeaderContainer>
        )
    }
    return <MobileHeader />
}
