import { useState } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicEffect'

interface ScrollProps {
    scrollThreshold: number
}

interface useScrollReturnType {
    isScrolling: boolean
}

export const useScroll = ({ scrollThreshold }: ScrollProps): useScrollReturnType => {
    const [isScrolling, setIsScrolling] = useState<boolean>(false)

    useIsomorphicLayoutEffect(() => {
        const logScrollState = () => {
            if (window.scrollY >= scrollThreshold) {
                setIsScrolling(true)
            } else {
                setIsScrolling(false)
            }
        }

        window.addEventListener('scroll', logScrollState)

        return () => window.removeEventListener('scroll', logScrollState)
    }, [])

    return {
        isScrolling,
    }
}
