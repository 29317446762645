import styled, { keyframes } from 'styled-components'

interface SNavProps {
    readonly backgroundColor?: string
    readonly textColor?: string
    readonly borderColor?: string
    readonly fill: boolean
    readonly animate: boolean
}

const slideRight = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(60px); }
    50% { transform: translateX(120px); }
    75% { transform: translateX(180px); }
    100% { transform: translateX(200px); }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 300px;
    position: fixed;
    left: 48px;
    top: 128px;
`

export const NavLink = styled.button<SNavProps>`
    height: ${props => (props.animate ? 'fit-content' : '100px')};
    width: ${props => (props.animate ? '8px' : '100px')};
    margin: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: ${props => (props.animate ? '16px' : '')};
    line-height: ${props => (props.animate ? '24px' : '')};
    text-decoration: none;
    justify-content: center;
    border-radius: ${props => (props.animate ? '0px' : '50%')};
    text-transform: ${props => (props.animate ? 'uppercase' : '')};
    position: ${props => (props.animate ? 'absolute' : 'relative')};
    top: ${props => (props.animate ? '20%' : '0')};
    left: ${props => (props.animate ? '10px' : '0')};
    overflow-wrap: ${props => (props.animate ? 'anywhere' : 'unset')};
    font-size: ${props => (props.animate ? '18px' : '14px')};
    font-weight: 600;
    box-shadow: ${props =>
        props.animate
            ? 'none'
            : 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'};
    border: ${props => (props.fill ? 'none' : `1px solid ${props.backgroundColor}`)};
    background-color: ${props => props.backgroundColor};
    color: white;
    /* name duration timing-function delay iteration-count direction fill-mode */
    animation-name: ${props => (props.fill ? slideRight : 'none')};
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    z-index: 1100;
    transition: ${props =>
        props.animate ? 'all 1s ease, overflow-wrap 0.8s ease, border-radius 0.1s linear' : 'none'};
    &:hover {
        transform: scale(1.1);
    }
`
