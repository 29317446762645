import { useIsomorphicLayoutEffect, useMediaQuery } from '@app/hooks'
import React, { useRef, useState } from 'react'

type TParentContext = {
    backgroundColor: string
    previousColor: string
    updateBackgroundColor: (color: string) => void
    resetParent: () => void
    isLargeScreen: boolean | undefined
    showLoader: boolean
}

const defaultValue = {
    backgroundColor: '',
    previousColor: '',
    updateBackgroundColor: () => undefined,
    resetParent: () => undefined,
    isLargeScreen: undefined,
    showLoader: true,
}

const defaultColor = '#172329'

export const ParentContext = React.createContext<TParentContext>(defaultValue)

export const ParentProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const [backgroundColor, setBackgroundColor] = useState<string>(defaultColor)

    const isLargeScreen = useMediaQuery('(min-width: 768px)')

    // loader state
    const [showLoader, setShowLoader] = useState<boolean>(true)

    // track the status if this is very first page load
    const firstLoad = useRef<boolean>(true)

    const oldColor = useRef('')

    const updateBackgroundColor = (color: string) => {
        oldColor.current = backgroundColor
        setBackgroundColor(color)
    }

    const resetParent = () => {
        oldColor.current = defaultColor
        setBackgroundColor(defaultColor)
    }

    // checks whether this is the first page load
    // if this is the first page load then a loader will be shown
    // this is the work around for white screen flashes while using media query in ssr

    useIsomorphicLayoutEffect(() => {
        let timeout: string | number | NodeJS.Timeout | undefined

        if (firstLoad.current) {
            firstLoad.current = false
            timeout = setTimeout(() => {
                setShowLoader(false)
            }, 1400)
        } else {
            setShowLoader(false)
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    const value = {
        backgroundColor,
        updateBackgroundColor,
        previousColor: oldColor.current,
        resetParent,
        isLargeScreen,
        showLoader,
    }
    return <ParentContext.Provider value={value}>{children}</ParentContext.Provider>
}
