import React from 'react'
import { LoaderContainer, LoaderDiv, Emoji } from './styles'

export const Loader = () => (
    <LoaderContainer>
        <LoaderDiv>
            <p>Hello</p>
            <Emoji>👋</Emoji>
        </LoaderDiv>
    </LoaderContainer>
)
