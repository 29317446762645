import React, { useState } from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { Header, IconContainer, MenuContainer, MenuItem } from './styles'
import { navigate } from 'gatsby'
import { useIsomorphicLayoutEffect, useParent } from '@app/hooks'
import { useLocation } from '@reach/router'

interface MobileMenuProps {
    hideMenu: () => void
}

type OnClickProps = {
    color: string
    link: string
}

const backgroundColorsByPathname: Record<string, string> = {
    '/experience': '#3a9487',
    '/about': '#8a5d7d',
    '/projects': '#578ea5',
    '/education': '#bc5d53',
}

export const MobileMenu = ({ hideMenu }: MobileMenuProps) => {
    const { pathname } = useLocation()

    const { updateBackgroundColor } = useParent()

    const handleOnClick = ({ color, link }: OnClickProps) => {
        hideMenu()
        updateBackgroundColor(color)
        navigate(link)
    }

    useIsomorphicLayoutEffect(() => {
        updateBackgroundColor(backgroundColorsByPathname[pathname] as string)
    }, [])

    return (
        <MenuContainer>
            <Header>
                <IconContainer onClick={hideMenu}>
                    <StaticImage
                        layout='fixed'
                        alt='close icon'
                        src='../../../images/close.png'
                        height={36}
                    />
                </IconContainer>
            </Header>
            {menuItems.map(item => (
                <MenuItem
                    role='link'
                    onClick={() => handleOnClick({ color: item.color, link: item.href })}
                    bgcolor={item.color}
                >
                    {item.text}
                </MenuItem>
            ))}
        </MenuContainer>
    )
}

const menuItems = [
    {
        href: '/experience',
        color: '#3a9487',
        text: 'Experience',
    },
    {
        href: '/projects',
        color: '#578ea5',
        text: 'Projects',
    },
    {
        href: '/education',
        color: '#bc5d53',
        text: 'Education',
    },
    {
        href: '/about',
        color: '#8a5d7d',
        text: 'About Me',
    },
    {
        href: '/blog',
        color: '#f49e4c',
        text: 'Blog',
    },
]
