import styled, { keyframes } from 'styled-components'

interface ItemProps {
    readonly bgcolor: string
}

const slideUpAnimation = keyframes`
    0% { transform: translateY(50px); opacity: 0}
    20% { transform: translateY(25px); opacity: 0.2}
    40% { transform: translateY(0px); opacity: 0.4}
    60% { transform: translateY(-25px); opacity: 0.6}
    80% { transform: translateY(-12px); opacity: 0.8}
    100% { transform: translateY(0px); opacity: 1}
`

export const MenuContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.95);
    text-transform: uppercase;
    user-select: none;
    overflow: hidden;
    z-index: 1200;
    a {
        animation-name: ${slideUpAnimation};
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: both;
    }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    position: absolute;
    background-color: black;
    right: 16px;
    top: 16px;
`

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    z-index: 1300;
`

export const MenuItem = styled.a<ItemProps>`
    font-size: 24px;
    font-family: monospace;
    text-decoration: none;
    color: ${props => props.bgcolor};
    padding: 48px 0px;
`
