import { Link } from 'gatsby'
import styled from 'styled-components'

export const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    position: fixed;
    top: 32px;
    left: 48px;
`

export const HeaderLink = styled(Link)`
    font-size: 24px;
    font-weight: 800;
    text-decoration: none;
    color: #b6e702;
    text-shadow: rgba(0, 0, 0, 0.19) 0px 20px 30px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
`
