import styled, { keyframes } from 'styled-components'

const textAnimation = keyframes`
    0% { transform: translateY(100%); opacity: 0; transform-origin: top left;}
    50% { transform: translateY(50%); opacity: 0.2; transform-origin: top left;}
    100% { transform: translateY(0); opacity: 1; transform-origin: top left;}
`

const iconAnimation = keyframes`
    0% { transform: rotate(15deg); tranform-origin: bottom center; opacity: 1;}
    20% { transform: rotate(10deg); tranform-origin: bottom center; opacity: 1;}
    40% { transform: rotate(-10deg); tranform-origin: bottom center; opacity: 1;}
    60% { transform: rotate(5deg); tranform-origin: bottom center; opacity: 1;}
    80% { transform: rotate(-10deg); tranform-origin: bottom center; opacity: 1;}
    100% { transform: rotate(15deg); tranform-origin: bottom center; opacity: 1;}
`

export const LoaderContainer = styled.section`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #172329;
`

export const LoaderDiv = styled.div`
    color: #b6e702;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-family: monospace;
    animation-name: ${textAnimation};
    animation-duration: 0.7s;
    animation-timing-function: linear;
    animation-fill-mode: both;
`
export const Emoji = styled.div`
    font-size: 48px;
    animation-name: ${iconAnimation};
    animation-duration: 0.7s;
    animation-delay: 0.7s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    opacity: 0;
`
