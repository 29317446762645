import { Link } from 'gatsby'
import styled from 'styled-components'

interface HeaderContainerProps {
    isscrolling: boolean
}

export const HeaderContainer = styled.header<HeaderContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    padding: 12px 16px;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${props => (props.isscrolling ? '#172329' : 'transparent')};
    border-top: ${props => (props.isscrolling ? '1px solid #497083' : 'transparent')};
    transition: background-color 0.3s linear;
    box-shadow: ${props => (props.isscrolling ? '2px 2px 10px #999' : '')};
`

export const HeaderLink = styled(Link)`
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
    color: #b6e702;
`
export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    height: 32px;
    width: 32px;
`
