import '../../index.css'

import React, { useRef } from 'react'

import { Header } from './header'
import { SideNavigation } from './side-navigation'

// styled components
import { Wrapper, Main, MobileWrapper } from './styles'
import { useParent, useIsomorphicLayoutEffect } from '@app/hooks'
import { useInternalPaths } from '@app/hooks/useInternalPaths'
import { useLocation } from '@reach/router'
import { Loader } from '@app/components/loader'

interface LayoutProps {
    children: React.ReactNode
    itemsdirection?: string
    is404?: boolean
}

export const Layout = ({ children, itemsdirection = 'column' }: LayoutProps) => {
    const { pathname } = useLocation()

    const paths: string[] = useInternalPaths()

    const is404 = pathname == '404' || pathname == '404.html' || !paths.includes(pathname)

    const { backgroundColor, previousColor, isLargeScreen, showLoader } = useParent()

    const ref = useRef<HTMLDivElement | null>(null)

    // background color change animation whenever pathname is changed
    useIsomorphicLayoutEffect(() => {
        if (ref.current) {
            ref.current.style.backgroundColor = `${previousColor}`
            ref.current.style.backgroundSize = '0% 0%'
        }

        const timeout = setTimeout(() => {
            if (ref.current) {
                ref.current.style.backgroundImage = `radial-gradient(${backgroundColor},${backgroundColor})`
                ref.current.style.backgroundSize = '100% 100%'
            }
        }, 500)

        return () => clearTimeout(timeout)
    }, [backgroundColor])

    if (showLoader || isLargeScreen == undefined) {
        return <Loader />
    }

    if (isLargeScreen) {
        return (
            <Wrapper
                ref={ref}
                itemsdirection={itemsdirection}
            >
                <Header />
                {!is404 && <SideNavigation pathname={pathname} />}
                <Main>{children}</Main>
            </Wrapper>
        )
    }

    return (
        <MobileWrapper
            ref={ref}
            itemsdirection={itemsdirection}
        >
            <Header />
            <Main>{children}</Main>
        </MobileWrapper>
    )
}
