import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Container, NavLink } from './styles'
import { useParent } from '@app/hooks/useParent'
import { useIsomorphicLayoutEffect } from '@app/hooks'

type OnClickProps = {
    color: string
    link: string
}

const backgroundColorsByPathname: Record<string, string> = {
    '/experience': '#3a9487',
    '/about': '#8a5d7d',
    '/projects': '#578ea5',
    '/education': '#bc5d53',
}

export const SideNavigation = ({ pathname }: { pathname: string }) => {
    // if its root path don't show the sidenavigation

    if (pathname === '/' || pathname === '/blog') return null

    const { updateBackgroundColor } = useParent()

    const handleOnClick = ({ color, link }: OnClickProps) => {
        updateBackgroundColor(color)
        navigate(link)
    }

    const [isReady, setIsReady] = useState<boolean>(false)

    useIsomorphicLayoutEffect(() => {
        setIsReady(false)
        const timeout = setTimeout(() => {
            setIsReady(true)
        }, 850)

        return () => clearTimeout(timeout)
    }, [pathname])

    useIsomorphicLayoutEffect(() => {
        updateBackgroundColor(backgroundColorsByPathname[pathname] as string)
    }, [])

    return (
        <Container>
            {sideNavLinks.map(link => {
                const isMatchingPath = pathname == link.href
                return (
                    <NavLink
                        onClick={() => handleOnClick({ link: link.href, color: link.color })}
                        role='link'
                        backgroundColor={link.color}
                        textColor='white'
                        fill={isMatchingPath}
                        animate={isMatchingPath && isReady}
                    >
                        {link.text}
                    </NavLink>
                )
            })}
        </Container>
    )
}

const sideNavLinks = [
    {
        href: '/about',
        color: '#8a5d7d',
        text: 'About Me',
    },
    {
        href: '/experience',
        color: '#3a9487',
        text: 'Experience',
    },
    {
        href: '/projects',
        color: '#578ea5',
        text: 'Projects',
    },
    {
        href: '/education',
        color: '#bc5d53',
        text: 'Education',
    },
]
