import React from 'react'
import { ParentContext } from '@app/context/ParentContext'

export const useParent = () => {
    const context = React.useContext(ParentContext)

    if (!context) {
        throw new Error('(useParentRef) hook must be used inside ParentRefProvider')
    }
    return context
}
