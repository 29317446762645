import React, { useState } from 'react'

import { HeaderContainer, HeaderLink, IconContainer } from './styles'
import { StaticImage } from 'gatsby-plugin-image'
import { MobileMenu } from '../menu'
import { useScroll } from '@app/hooks'

export const MobileHeader = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const navbarHeight = 60
    const { isScrolling } = useScroll({ scrollThreshold: navbarHeight })

    const handleOnClick = () => {
        setShowMenu(previousValue => !previousValue)
    }

    const hideMenu = () => {
        setShowMenu(false)
    }

    return (
        <>
            <HeaderContainer isscrolling={isScrolling}>
                <HeaderLink to='/'>kapilbastola.com.np</HeaderLink>
                <IconContainer onClick={handleOnClick}>
                    <StaticImage
                        layout='fixed'
                        alt='menu icon'
                        src='../../../images/menu.png'
                        height={48}
                        width={48}
                        transformOptions={{
                            fit: 'cover',
                            cropFocus: 'attention',
                        }}
                    />
                </IconContainer>
            </HeaderContainer>
            {showMenu && <MobileMenu hideMenu={hideMenu} />}
        </>
    )
}
