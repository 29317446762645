import styled from 'styled-components'

interface WrapperProps {
    itemsdirection: string
}

const CommonWrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: ${props => props.itemsdirection};
    align-items: center;
    background-color: #172329;
    background-image: linear-gradient(#172329, #172329);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 0% 0%;
    transition:
        background-size 0.7s,
        color 0.5s;
`

export const Wrapper = styled(CommonWrapper)`
    height: 100vh;
    width: 100vw;
    justify-content: center;
`

export const MobileWrapper = styled(CommonWrapper)`
    /* s */
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    width: 100%;
    justify-content: start;
    padding-top: 76px;
`

export const Main = styled.main`
    width: 90%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`
